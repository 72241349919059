<template>
  <v-container>
    <v-form lazy-validation ref="step5">
      <v-row>
        <v-col class="py-2">ජාතික හැඳුනුම්පතේ විස්තර.</v-col>
      </v-row>
      <v-row>
        <v-col class="py-2" cols="12" md="3" sm="12">
          <v-text-field
            :error-messages="nicExistsError"
            :loading="loading"
            :rules="nicRule"
            @blur="checkNicValidity"
            dense
            label="ජාතික හැඳුනුම්පත් අංකය "
            ref="nic"
            v-model="nic"
            value
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        ගිණුම් විස්තර තහවුරු කර ගැනීම සදහා පමණක් ජාතික හැදුනුම්පතෙහි ඡායාරූප භාවිතා කෙරේ. මෙම විස්තර කිසිදු අයුරකින්
        බාහිර පාර්ශවයන්ට නැරඹිය නොහැක. ගිණුමේ විස්තර තහවුරු කළ පසු ඔබගේ ගිණුම සක්‍රීය ගිණුමක් ලෙස පෙන්වනු ඇත.
      </v-row>
      <div class="body-2 mt-3 mb-8">
        (ජාතික හැඳුනුම්පත නොමැති නම් හෝ අපැහැදිලි අවස්ථාවක රියදුරු බලපත්‍රය හෝ විදේශ ගමන් බලපත්‍රයේ හැඳුනුම්පත් අංකය
        සහිත කොටස භාවිතා කළ හැක)
      </div>
      <v-row>
        <v-col class="py-2" cols="12" md="6" sm="12">
          <v-file-input
            :clearable="false"
            :rules="[valid || 'ඉදිරිපස ඡායාරූපය අවශ්‍යයි']"
            @change="selectNicFrontPhoto"
            accept="image/*"
            dense
            label="ඉදිරිපස ඡායාරූපය"
            prepend-icon
            prepend-inner-icon="mdi-camera"
            show-size
          ></v-file-input>
          <!-- <span class="caption mt-0" style="color: #FF5252" v-if="!valid">ඉදිරිපස ඡායාරූපය අවශ්‍ය වේ</span> -->
        </v-col>
        <v-col class="py-2" cols="12" lg="4" md="6" sm="12">
          <v-file-input
            :clearable="false"
            :rules="[valid || 'පසුපස ඡායාරූපය අවශ්‍යයි']"
            @change="selectNicBackPhoto"
            accept="image/*"
            dense
            label="පසුපස ඡායාරූපය"
            prepend-icon
            prepend-inner-icon="mdi-camera"
            show-size
          ></v-file-input>
          <!-- <span class="caption mt-0" style="color: #FF5252" v-if="!valid">පසුපස ඡායාරූපය අවශ්‍ය වේ</span> -->
        </v-col>
        <v-col class="py-2" cols="12" lg="2" md="3" sm="12">
          <v-btn
            :disabled="!nicFrontPhotoFile || !nicBackPhotoFile"
            :loading="btnLoading"
            @click="uploadImage"
            block
            color="accent"
            elevation="4"
          >තහවුරු කරන්න</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-2" cols="12" md="6" sm="12">
          <v-img
            :src="nicFrontPhotoURL"
            aspect-ratio="1"
            class="grey lighten-2"
            contain
            max-height="300"
          ></v-img>
        </v-col>
        <v-col class="py-2" cols="12" md="6" sm="12">
          <v-img
            :src="nicBackPhotoURL"
            aspect-ratio="1"
            class="grey lighten-2"
            contain
            max-height="300"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col>ඉහත ඡායාරූප ඇතුලත් කිරීමේදී ගැටළු මතු වේ නම් පමණක්, ජාතික හැඳුනුම්පතෙහි දෙපස ඡායාරූප 0717-928628 අංකයට Whatsapp/Viber/Imo හෝ ashirwadaproposals@gmail.com ව‌ෙත ඊමේල් කර 0717-928627 අංකයට ඇමතුමක් ලබා දෙන්න.</v-col>
      </v-row>

      <v-btn
        @click="$emit('stepChanged', 4)"
        class="float-left mb-2"
        color="secondary"
        elevation="4"
      >
        <v-icon class="mr-2" dark>mdi-arrow-left-bold</v-icon>
        <span class="d-none d-sm-flex">පෙර පිටුව</span>
      </v-btn>
      <v-btn @click="next" class="float-right mb-2" color="secondary" elevation="4">
        <span class="d-none d-sm-flex">ඊලග පිටුව</span>
        <v-icon class="ml-2" dark>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { UPDATE_REG_FORM, SHOW_ALERT } from "../../store/mutation-types";
import { ApiService } from "../../services/api.service";

export default {
  data: () => ({
    btnLoading: false,
    nicFrontPhotoFile: "",
    nicFrontPhotoURL: "",
    nicBackPhotoFile: "",
    nicBackPhotoURL: "",
    valid: false,
    loading: false,
    nicExistsError: ""
  }),

  mounted() {
    this.nicFrontPhotoURL = require("../../assets/images/image_placeholder.png");
    this.nicBackPhotoURL = require("../../assets/images/image_placeholder.png");
  },

  computed: {
    nic: {
      get() {
        return this.$store.state.regForm.nic;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: "nic", value: value });
      }
    },
    nicRule: function () {
      return [
        v => !!v || 'අවශ්‍යයි',
        v => /^[0-9]{9}[vV]|^[0-9]{12}$/.test(v) || 'හැඳුනුම්පත් අංකය වලංගු නොවේ'
      ];
    },
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    next() {
      if (this.$refs.step5.validate() && !this.nicExistsError) {
        this.$emit('stepChanged', 6);
      } else { 
        this.showAlert({ message: "මීලඟ පියවරට යාමට පෙර කරුණාකර ඔබගේ හැඳුනුම්පත් අංකය සහ ඡායාරූප තහවුරු කරන්න", type: "error" });
      }
    },

    selectNicFrontPhoto: function (file) {
      if (file) {
        this.nicFrontPhotoFile = file;
        this.nicFrontPhotoURL = URL.createObjectURL(file);
      }
    },

    selectNicBackPhoto: function (file) {
      if (file) {
        this.nicBackPhotoFile = file;
        this.nicBackPhotoURL = URL.createObjectURL(file);
      }
    },

    uploadImage: async function () {
      this.btnLoading = true;

      const fd = new FormData();
      fd.append("nicFront", this.nicFrontPhotoFile);
      fd.append("nicBack", this.nicBackPhotoFile);
      fd.append("category", "nic");

      try {
        const response = await ApiService.post("files/upload", fd);
        this.$store.commit(UPDATE_REG_FORM, {
          property: "nicFrontPhoto",
          value: response.data[0]
        });
        this.$store.commit(UPDATE_REG_FORM, {
          property: "nicBackPhoto",
          value: response.data[1]
        });
        this.valid = true;
      } catch (error) {
        console.log(error);
      }

      this.btnLoading = false;
    },

    checkNicValidity: async function (event) {
      if (this.$refs.nic.validate()) {
        this.loading = true;
        try {
          const url = "customers/check-nic";
          const params = { params: { nic: event.target.value } };
          const response = await ApiService.get(url, params);
          if (response.data.isAlreadyUsed) {
            this.nicExistsError = "ඇතුළත් කළ හැඳුනුම්පත් අංකය දැනටමත් භාවිතා කර ඇත";
          } else {
            this.nicExistsError = "";
          }
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    }
  }
};
</script>