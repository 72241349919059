<template>
  <v-app>
    <Alert />
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark>
      <v-toolbar-title class="ml-0 pl-4" style="width: 300px">
        <router-link to="/">
          <v-img alt="Vuetify" max-height="60" max-width="150" src="../../assets/images/logo.png" />
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <v-content>
      <v-container class="mb-5">
        <v-row no-gutters>
          <div class="mt-4">
            <h4>ඔබේ විස්තර</h4>
            <h6>ආශිර්වාද මංගල සේවය සඳහා ලියාපදිංචි වීම (සත්‍ය තොරතුරු පමණක් ඇතුලත් කරන්න)</h6>
          </div>
        </v-row>
        <v-divider></v-divider>
        <RegisterForm />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Alert from '../../components/Alert';
import RegisterForm from "../Register/RegisterForm";

export default {
  name: "RegistrationLayout",

  components: {
    RegisterForm,
    Alert
  }
}
</script>

<style>
</style>