<template>
  <div>
    <v-container>
      <v-form lazy-validation ref="step1">
        <v-row>
          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="['ස්ත්‍රී', 'පුරුෂ']"
              :rules="[requiredRule]"
              dense
              label="ස්ත්‍රී/පුරුෂ"
              v-model="gender"
            ></v-select>
          </v-col>
          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-menu
              :close-on-content-click="false"
              min-width="290px"
              offset-y
              ref="menu"
              transition="scale-transition"
              v-model="menu"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :rules="[requiredRule]"
                  dense
                  label="උපන් දිනය "
                  v-model="dob"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker :allowed-dates="allowedDates" no-title scrollable v-model="dob">
                <v-spacer></v-spacer>
                <v-btn @click="menu = false" color="primary" text>Cancel</v-btn>
                <v-btn @click="$refs.menu.save(dob)" color="primary" text>OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="metaData.countries"
              :rules="[requiredRule]"
              dense
              item-text="name"
              label="ජීවත්ව සිටින රට"
              v-model="livingCountry"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="provinces"
              :rules="[requiredRule]"
              @change="updateDistricts"
              dense
              item-text="name"
              label="පළාත (ශ්‍රී ලංකාව තුල)"
              v-model="province"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="districts"
              :rules="[requiredRule]"
              dense
              item-text="name"
              label="දිස්ත්‍රික්කය"
              v-model="district"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-text-field :rules="[requiredRule]" dense label="නගරය" v-model="town"></v-text-field>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="races"
              :rules="[requiredRule]"
              dense
              item-text="name"
              label="ජාතිය"
              v-model="race"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="religions"
              :rules="[requiredRule]"
              dense
              item-text="name"
              label="ආගම"
              v-model="religion"
            ></v-select>
          </v-col>
          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="casts"
              :rules="[requiredRule]"
              dense
              item-text="name"
              label="කුළය"
              v-model="cast"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="marriageStatuses"
              :rules="[requiredRule]"
              dense
              item-text="status"
              label="විවාහක තත්වය"
              v-model="marriageStatus"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6" v-if="showNumberOfChildren">
            <v-select :items="numbers" dense label="දරුවන් සංඛ්‍යාව" v-model="numberOfChildren"></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6" v-if="showRespOfChildren">
            <v-select
              :items="responsibilities"
              dense
              item-text="label"
              item-value="value"
              label="දරුවන්ග‌ේ වගකීම්"
              v-model="respOfChildren"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="educations"
              :rules="[requiredRule]"
              dense
              item-text="level"
              label="අධ්‍යාපන මට්ටම"
              v-model="educationLevel"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="jobs"
              :rules="[requiredRule]"
              dense
              item-text="name"
              label="රැකියා අංශය"
              v-model="job"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6" v-if="showForignWorkingStatus">
            <v-select :items="forignJobs" dense label="විදේශ රැකියා තත්වය" v-model="foreignJobStatus"></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-text-field
              :rules="[requiredRule, alphaRule]"
              dense
              label="තනතුර"
              v-model="designation"
            ></v-text-field>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-text-field
              :rules="[requiredRule]"
              dense
              label="මාසික ආදායම(ඉලක්කමෙන්)"
              type="number"
              v-model="incomeValue"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <a
            class="body-2 pt-3"
            href="https://sinhalaunicode.gishan.net/write"
            target="_blank"
            v-if="$vuetify.breakpoint.smAndUp"
          >සිංහලෙන් ටයිප් කිරීම සඳහා පිවිසෙන්න</a>
          <v-spacer></v-spacer>
          <v-btn @click="next" class="float-right mb-2" color="secondary" elevation="4">
            <span class="d-none d-sm-flex">ඊලග පිටුව</span>
            <v-icon class="ml-2" dark>mdi-arrow-right-bold</v-icon>
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
    <a
      class="d-flex justify-center body-2 pt-3"
      href="https://sinhalaunicode.gishan.net/write"
      target="_blank"
      v-if="$vuetify.breakpoint.xs"
    >සිංහලෙන් ටයිප් කිරීම සඳහා පිවිසෙන්න</a>
  </div>
</template>

<script>
import { ApiService } from "../../services/api.service";
import { mapState, mapGetters } from "vuex";
import { UPDATE_REG_FORM } from "../../store/mutation-types";

export default {
  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    alphaRule: v => /^([^0-9]*)$/.test(v) || 'මෙම ක්ෂේත්‍රයේ අඩංගු විය හැක්කේ අකාරාදී අක්ෂර පමණි',
    // alphaRule: v => /^[a-z\u0D80-\u0DFF \u0B80-\u0BFF]+$/i.test(v) || 'මෙම ක්ෂේත්‍රයේ අඩංගු විය හැක්කේ අකාරාදී අක්ෂර පමණි',
    menu: false,
    districts: [],
    // towns: [],
    showNumberOfChildren: false,
    showForignWorkingStatus: false,
    showRespOfChildren: false,
    numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    responsibilities: [
      { label: "ඇත", value: true },
      { label: "නැත", value: false }
    ],
    forignJobs: ['PR', 'TR']
  }),

  mounted: function () {
    this.$store.dispatch('fetchMetaData');
  },

  computed: {
    ...mapState(["metaData"]),

    provinces: function () {
      const provinces = JSON.parse(JSON.stringify(this.metaData.provinces));
      provinces.shift(); // Remove 1st element from provinces array
      return provinces;
    },

    races: function () {
      const races = JSON.parse(JSON.stringify(this.metaData.races));
      races.shift(); // Remove 1st element from races array
      return races;
    },

    religions: function () {
      const religions = JSON.parse(JSON.stringify(this.metaData.religions));
      religions.shift(); // Remove 1st element from religions array
      return religions;
    },

    casts: function () {
      const casts = JSON.parse(JSON.stringify(this.metaData.casts));
      casts.shift(); // Remove 1st element from casts array
      return casts;
    },

    marriageStatuses: function () {
      const marriageStatuses = JSON.parse(JSON.stringify(this.metaData.marriageStatuses));
      marriageStatuses.shift(); // Remove 1st element from marriageStatuses array
      return marriageStatuses;
    },

    educations: function () {
      const educations = JSON.parse(JSON.stringify(this.metaData.educations));
      educations.shift(); // Remove 1st element from educations array
      return educations;
    },

    jobs: function () {
      const jobs = JSON.parse(JSON.stringify(this.metaData.jobs));
      jobs.shift(); // Remove 1st element from jobs array
      return jobs;
    },

    gender: {
      get() {
        return this.$store.state.regForm.gender;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "gender",
          value: value
        });
      }
    },
    dob: {
      get() {
        return this.$store.state.regForm.dob;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: "dob", value: value });
      }
    },
    livingCountry: {
      get() {
        return this.$store.state.regForm.livingCountry;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "livingCountry",
          value: value
        });
      }
    },
    province: {
      get() {
        return this.$store.state.regForm.province;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "province",
          value: value
        });
      }
    },
    district: {
      get() {
        return this.$store.state.regForm.district;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "district",
          value: value
        });
      }
    },
    town: {
      get() {
        return this.$store.state.regForm.town;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: "town", value: value });
      }
    },
    race: {
      get() {
        return this.$store.state.regForm.race;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: "race", value: value });
      }
    },
    religion: {
      get() {
        return this.$store.state.regForm.religion;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "religion",
          value: value
        });
      }
    },
    cast: {
      get() {
        return this.$store.state.regForm.cast;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: "cast", value: value });
      }
    },
    marriageStatus: {
      get() {
        return this.$store.state.regForm.marriageStatus;
      },
      set(value) {
        if (value == "දික්කසාද" || value == "වැන්ඳඹු") {
          this.showNumberOfChildren = true;
        } else {
          this.showNumberOfChildren = false;
          this.showRespOfChildren = false;
          this.$store.commit(UPDATE_REG_FORM, { property: "numberOfChildren", value: "" });
          this.$store.commit(UPDATE_REG_FORM, { property: "respOfChildren", value: false });
        }
        this.$store.commit(UPDATE_REG_FORM, {
          property: "marriageStatus",
          value: value
        });
      }
    },
    numberOfChildren: {
      get() {
        return this.$store.state.regForm.numberOfChildren;
      },
      set(value) {
        if (value > 0) {
          this.showRespOfChildren = true;
        } else {
          this.showRespOfChildren = false;
          this.$store.commit(UPDATE_REG_FORM, { property: "respOfChildren", value: false });
        }
        this.$store.commit(UPDATE_REG_FORM, {
          property: "numberOfChildren",
          value: value
        });
      }
    },
    respOfChildren: {
      get() {
        return this.$store.state.regForm.respOfChildren;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "respOfChildren",
          value: value
        });
      }
    },
    educationLevel: {
      get() {
        return this.$store.state.regForm.educationLevel;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "educationLevel",
          value: value
        });
      }
    },
    job: {
      get() {
        return this.$store.state.regForm.job;
      },
      set(value) {
        if (value == "විදේශ රැකියා") {
          this.showForignWorkingStatus = true;
        } else {
          this.showForignWorkingStatus = false;
          this.$store.commit(UPDATE_REG_FORM, { property: "foreignJobStatus", value: "" });
        }
        
        this.$store.commit(UPDATE_REG_FORM, { property: "job", value: value });
      }
    },
    foreignJobStatus:{
      get() {
        return this.$store.state.regForm.foreignJobStatus;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "foreignJobStatus",
          value: value
        });
      }
    },
    designation: {
      get() {
        return this.$store.state.regForm.designation;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "designation",
          value: value
        });
      }
    },
    incomeValue: {
      get() {
        return this.$store.state.regForm.incomeValue;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "incomeValue",
          value: value
        });
      }
    }
  },

  methods: {
    next() {
      if (this.$refs.step1.validate()) {
        this.$emit('stepChanged', 2);
      }
    },

    allowedDates: val => parseInt(val.split('-')[0]) < new Date().getFullYear(),

    updateDistricts: async function (province) {
      try {
        const url = "districts";
        const params = { params: { province: province } };
        const response = await ApiService.get(url, params);
        this.districts = response.data;
        this.$store.commit(UPDATE_REG_FORM, { property: "district", value: "" });
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>