<template>
  <div>
    <v-container>
      <v-form lazy-validation ref="step2">
        <v-row>
          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="metaData.heights"
              :rules="[requiredRule]"
              dense
              item-text="value"
              label="උස"
              v-model="height"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="metaData.bodyShapes"
              :rules="[requiredRule]"
              dense
              item-text="shape"
              label="ශරීර හැඩය"
              v-model="bodyShape"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="metaData.skinColors"
              :rules="[requiredRule]"
              dense
              item-text="color"
              label="සමෙහි වර්ණය"
              v-model="skinColor"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="metaData.brothers"
              :rules="[requiredRule]"
              dense
              item-text="value"
              label="සහෝදරයන් සංඛ්‍යාව"
              v-model="brothersCount"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="metaData.sisters"
              :rules="[requiredRule]"
              dense
              item-text="value"
              label="සහෝදරියන් සංඛ්‍යාව"
              v-model="sistersCount"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="assetItems"
              dense
              item-text="label"
              item-value="value"
              label="වත්කම්/දායාද"
              v-model="assets"
            ></v-select>
          </v-col>

          <v-col class="py-2" cols="12" v-if="assets">
            <v-textarea
              :rules="[lengthRule]"
              counter
              dense
              label="වත්කම්/දායාද විස්තර"
              rows="1"
              v-model="assetsDetails"
            ></v-textarea>
          </v-col>

          <v-col class="py-2" cols="12">
            <v-textarea
              :rules="[lengthRule]"
              counter
              dense
              label="වෙනත් විස්තර"
              rows="1"
              v-model="otherDetails"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-btn @click="$emit('stepChanged', 1)" class="mb-2" color="secondary" elevation="4">
            <v-icon class="mr-2" dark>mdi-arrow-left-bold</v-icon>
            <span class="d-none d-sm-flex">පෙර පිටුව</span>
          </v-btn>
          <v-spacer></v-spacer>
          <a
            class="body-2 pt-3"
            href="https://sinhalaunicode.gishan.net/write"
            target="_blank"
            v-if="$vuetify.breakpoint.smAndUp"
          >සිංහලෙන් ටයිප් කිරීම සඳහා පිවිසෙන්න</a>
          <v-spacer></v-spacer>
          <v-btn @click="next" class="mb-2" color="secondary" elevation="4">
            <span class="d-none d-sm-flex">ඊලග පිටුව</span>
            <v-icon class="ml-2" dark>mdi-arrow-right-bold</v-icon>
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
    <a
      class="d-flex justify-center body-2 pt-3"
      href="https://sinhalaunicode.gishan.net/write"
      target="_blank"
      v-if="$vuetify.breakpoint.xs"
    >සිංහලෙන් ටයිප් කිරීම සඳහා පිවිසෙන්න</a>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { UPDATE_REG_FORM } from "../../store/mutation-types";

export default {
  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    lengthRule: v => v.length <= 100 || 'අක්ෂර 100 ට වඩා අඩු විය යුතුය',
    alphaRule: v => /^([^0-9]*)$/.test(v) || 'මෙම ක්ෂේත්‍රයේ අඩංගු විය හැක්කේ අකාරාදී අක්ෂර පමණි',
    assetItems: [
      { label: "ඇත", value: true },
      { label: "නැත", value: false }
    ]
  }),

  computed: {
    ...mapState(["metaData"]),

    height: {
      get() {
        return this.$store.state.regForm.height;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "height",
          value: value
        });
      }
    },
    skinColor: {
      get() {
        return this.$store.state.regForm.skinColor;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "skinColor",
          value: value
        });
      }
    },
    bodyShape: {
      get() {
        return this.$store.state.regForm.bodyShape;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "bodyShape",
          value: value
        });
      }
    },
    // foodHabbit: {
    //   get() {
    //     return this.$store.state.regForm.foodHabbit;
    //   },
    //   set(value) {
    //     this.$store.commit(UPDATE_REG_FORM, {
    //       property: "foodHabbit",
    //       value: value
    //     });
    //   }
    // },
    // smokeUsage: {
    //   get() {
    //     return this.$store.state.regForm.smokeUsage;
    //   },
    //   set(value) {
    //     this.$store.commit(UPDATE_REG_FORM, {
    //       property: "smokeUsage",
    //       value: value
    //     });
    //   }
    // },
    // liquireUsage: {
    //   get() {
    //     return this.$store.state.regForm.liquireUsage;
    //   },
    //   set(value) {
    //     this.$store.commit(UPDATE_REG_FORM, {
    //       property: "liquireUsage",
    //       value: value
    //     });
    //   }
    // },
    brothersCount: {
      get() {
        return this.$store.state.regForm.brothersCount;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "brothersCount",
          value: value
        });
      }
    },
    sistersCount: {
      get() {
        return this.$store.state.regForm.sistersCount;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "sistersCount",
          value: value
        });
      }
    },
    assets: {
      get() {
        return this.$store.state.regForm.assets;
      },
      set(value) {
        if (!value) {
          this.$store.commit(UPDATE_REG_FORM, { property: "assetsDetails", value: "" });
        }
        this.$store.commit(UPDATE_REG_FORM, {
          property: "assets",
          value: value
        });
      }
    },
    assetsDetails: {
      get() {
        return this.$store.state.regForm.assetsDetails;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "assetsDetails",
          value: value
        });
      }
    },
    otherDetails: {
      get() {
        return this.$store.state.regForm.otherDetails;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "otherDetails",
          value: value
        });
      }
    }
  },

  methods: {
    next() {
      if (this.$refs.step2.validate()) {
        this.$emit('stepChanged', 3);
      }
    }
  }
};
</script>

<style>
</style>