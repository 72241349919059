<template>
  <div>
    <v-container class="mb-4">
      <v-form lazy-validation ref="step4">
        <v-row class="mb-5" no-gutters>
          <v-col
            cols="12"
            sm="12"
          >ඡායාරූපයක් ඇතුලත් කරන සාමාජිකයින් හට අනෙකුත් සාමාජිකයින්ට වඩා ඉහල ප්‍රතිඵල හිමි වේ.</v-col>
          <v-col
            cols="12"
            sm="12"
          >(සත්‍ය ඡායාරූප පමණක් ඇතුලත් කරන්න.අසත්‍ය ඡායාරූප ඇතුලත් ගිණුම් නොසලකා හැරේ.)</v-col>
        </v-row>

        <v-row>
          <v-col
            :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center ml-5':''"
            cols="12"
            lg="4"
            md="6"
          >
            <v-image-input
              :hideActions="hideActions"
              :imageHeight="$vuetify.breakpoint.name == 'xs' ? 212 : 256"
              :imageWidth="$vuetify.breakpoint.name == 'xs' ? 212 : 256"
              clearable
              image-format="jpeg"
              imageMinScaling="contain"
              overlayPadding="0px"
              scalingSliderColor="accent"
              v-model="profilePictureFile"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6">
            <v-select
              :disabled="!profilePictureFile"
              :items="privacyLevel"
              dense
              item-text="label"
              item-value="value"
              label="ඡායාරූපය පෙන්විය යුත්තේ"
              v-model="profPicShowStatus"
            ></v-select>
            <v-text-field
              :rules="[requiredRule]"
              dense
              hint="ඔබගේ ඡායාරූප මුරපදය සුරැකිව තබාගන්න.ඔබ කැමති සාමාජිකයන්ට පමණක් මෙම මුරපදය ලබා දීමෙන් 
            ඔවුන්ට පමණක් ඡායාරූපය නැරඹිය හැක."
              label="ඡායාරෑප මුරපදය"
              type="password"
              v-if="isPhotoPasswordFieldVisible"
              v-model="photoPassword"
            ></v-text-field>
            <v-btn
              :disabled="!profilePictureFile"
              :loading="btnLoading"
              @click="uploadImage"
              block
              class="mt-2"
              color="accent"
              elevation="4"
            >තහවුරු කරන්න</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-btn @click="$emit('stepChanged', 3)" class="float-left mb-2" color="secondary" elevation="4">
      <v-icon class="mr-2" dark>mdi-arrow-left-bold</v-icon>
      <span class="d-none d-sm-flex">පෙර පිටුව</span>
    </v-btn>
    <v-btn @click="next" class="float-right mb-2" color="secondary" elevation="4">
      <span class="d-none d-sm-flex">ඊලග පිටුව</span>
      <v-icon class="ml-2" dark>mdi-arrow-right-bold</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { UPDATE_REG_FORM } from "../../store/mutation-types";
import { ApiService } from "../../services/api.service";
import { Helpers } from "../../helpers";
import VImageInput from 'vuetify-image-input/a-la-carte';

export default {
  components: {
    VImageInput,
  },

  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    privacyLevel: [
      { label: "ඕනෑම කෙනෙකුට", value: 0 },
      { label: "සාමාජිකයින්ට-පමණයි", value: 1 },
      { label: "ඡායාරූප මුරපදය දන්නා අයට පමණයි", value: 2 }
    ],
    profilePictureFile: null,
    profilePictureURL: "",
    btnLoading: false
  }),

  computed: {
    ...mapState(["metaData"]),

    photoPassword: {
      get() {
        return this.$store.state.regForm.photoPassword;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "photoPassword",
          value: value
        });
      }
    },
    profPicShowStatus: {
      get() {
        return this.$store.state.regForm.profPicShowStatus;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, {
          property: "profPicShowStatus",
          value: value
        });
      }
    },
    isPhotoPasswordFieldVisible: function () {
      if (this.profilePictureFile && this.profPicShowStatus == 2) {
        return true;
      }
      return false;
    },
    hideActions: function () {
      if (this.profilePictureFile) {
        return false;
      }
      return true;
    }
  },

  methods: {
    selectProfilePicture: function (file) {
      if (file) {
        this.profilePictureFile = file;
        this.profilePictureURL = URL.createObjectURL(file);
      }
    },

    uploadImage: async function () {
      this.btnLoading = true;

      const fd = new FormData();
      await fd.append("profilePicture", Helpers.base64ToFile(this.profilePictureFile, "Profile"));
      fd.append("category", "profile");

      try {
        const response = await ApiService.post("files/upload", fd);
        this.$store.commit(UPDATE_REG_FORM, {
          property: "profilePicture",
          value: response.data
        });
      } catch (error) {
        console.log(error);
      }

      this.btnLoading = false;
    },

    next() {
      if (this.$refs.step4.validate()) {
        this.$emit('stepChanged', 5);
      }
    }
  }
};
</script>

<style>
</style>