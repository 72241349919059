<template>
  <v-container>
    <v-row>
      <v-col class="py-2">හඳහනෙහි තොරතුරු (ම‌ෙම තොරතුරු ඔබග‌ේ ගිණුම‌ෙහි න‌ොප‌ෙන්වයි)</v-col>
    </v-row>
    <v-row>
      <v-col class="py-2" cols="12" md="4" sm="12">
        <v-select
          :items="metaData.countries"
          dense
          item-text="name"
          label="උපන් රට"
          v-model="birthCountry"
        ></v-select>
      </v-col>
      <v-col class="py-2" cols="12" md="4" sm="12">
        <v-text-field dense label="උපන් ස්ථානය" v-model="birthPlace"></v-text-field>
      </v-col>
      <v-col class="py-2" cols="12" md="4" sm="12">
        <v-dialog
          :return-value.sync="birthTime"
          persistent
          ref="dialog"
          v-model="timeModal"
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field dense label="උපන් වේලාව" readonly v-model="birthTime" v-on="on"></v-text-field>
          </template>
          <v-time-picker full-width v-if="timeModal" v-model="birthTime">
            <v-spacer></v-spacer>
            <v-btn @click="timeModal = false" color="primary" text>Cancel</v-btn>
            <v-btn @click="$refs.dialog.save(birthTime)" color="primary" text>OK</v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        style="color: #FF5252"
      >(ඉහත ඔබ දෙනු ලබන තොරතුරු අනුව ඔබගේ ජන්ම පත්‍රය ආයතන‌ය විසින් සකසනු ලබයි)</v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col class="py-2" cols="12" md="4" sm="12">
        <v-select
          :items="metaData.lagna"
          dense
          disabled
          item-text="name"
          label="ලග්නය"
          v-model="lagna"
        ></v-select>
      </v-col>
      <v-col class="py-2" cols="12" md="4" sm="12">
        <v-select
          :items="metaData.gana"
          dense
          disabled
          item-text="name"
          label="ඝණය"
          v-model="ganaya"
        ></v-select>
      </v-col>
      <v-col class="py-2" cols="12" md="4" sm="12">
        <v-select
          :items="metaData.nekath"
          dense
          disabled
          item-text="name"
          label="නැකත"
          v-model="nekatha"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" lg="1" sm="2">
        <v-select :items="numbers" dense disabled label="රවි" v-model="ravi"></v-select>
      </v-col>
      <v-col cols="3" lg="1" sm="2">
        <v-select :items="numbers" dense disabled label="චන්ද්‍ර" v-model="chandra"></v-select>
      </v-col>
      <v-col cols="3" lg="1" sm="2">
        <v-select :items="numbers" dense disabled label="කුජ" v-model="kuja"></v-select>
      </v-col>
      <v-col cols="3" lg="1" sm="2">
        <v-select :items="numbers" dense disabled label="බුධ" v-model="budha"></v-select>
      </v-col>
      <v-col cols="3" lg="1" sm="2">
        <v-select :items="numbers" dense disabled label="ගුරු" v-model="guru"></v-select>
      </v-col>
      <v-col cols="3" lg="1" sm="2">
        <v-select :items="numbers" dense disabled label="රාහු" v-model="rahu"></v-select>
      </v-col>
      <v-col cols="3" lg="1" sm="2">
        <v-select :items="numbers" dense disabled label="සිකුරු" v-model="sikuru"></v-select>
      </v-col>
      <v-col cols="3" lg="1" sm="2">
        <v-select :items="numbers" dense disabled label="ශනි" v-model="shani"></v-select>
      </v-col>
      <v-col cols="3" lg="1" sm="2">
        <v-select :items="numbers" dense disabled label="කේතු" v-model="kethu"></v-select>
      </v-col>
    </v-row>

    <v-btn @click="$emit('stepChanged', 5)" class="float-left mb-2" color="secondary" elevation="4">
      <v-icon class="mr-2" dark>mdi-arrow-left-bold</v-icon>
      <span class="d-none d-sm-flex">පෙර පිටුව</span>
    </v-btn>
    <v-btn
      @click="$emit('stepChanged', 7)"
      class="float-right mb-2"
      color="secondary"
      elevation="4"
    >
      <span class="d-none d-sm-flex">ඊලග පිටුව</span>
      <v-icon class="ml-2" dark>mdi-arrow-right-bold</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { UPDATE_REG_FORM } from "../../store/mutation-types";

export default {
  data: () => ({
    timeModal: false,
    // lagnaList: ["මේෂ", "වෘෂභ", "මිථුන", "කටක", "සින්හ", "කන්‍යා", "තුලා", "වෘශ්චික", "ධනු", "මකර", "කුම්භ", "මීන", "නැත", "නොදනී"],
    // ganaList: ["රාක්ෂ", "මනුෂ්‍ය", "දේව", "නැත", "නොදනී"],
    // asterismList: ["අස්විද", "අස්ලිය", "මා", "සා"],
    // items: ["රවි", "චන්ද්‍ර", "කුජ", "බුධ", "ගුරු", "රාහු", "සිකුරු", "ශනි", "කේතු"],
    numbers: ['-', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  }),

  computed: {
    ...mapState(['metaData']),

    birthCountry: {
      get() {
        return this.$store.state.regForm.birthCountry;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'birthCountry', value: value });
      }
    },
    birthPlace: {
      get() {
        return this.$store.state.regForm.birthPlace;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'birthPlace', value: value });
      }
    },
    birthTime: {
      get() {
        return this.$store.state.regForm.birthTime;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'birthTime', value: value });
      }
    },
    lagna: {
      get() {
        return this.$store.state.regForm.lagna;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'lagna', value: value });
      }
    },
    ganaya: {
      get() {
        return this.$store.state.regForm.ganaya;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'ganaya', value: value });
      }
    },
    nekatha: {
      get() {
        return this.$store.state.regForm.nekatha;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'nekatha', value: value });
      }
    },
    ravi: {
      get() {
        return this.$store.state.regForm.ravi;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'ravi', value: value });
      }
    },
    chandra: {
      get() {
        return this.$store.state.regForm.chandra;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'chandra', value: value });
      }
    },
    kuja: {
      get() {
        return this.$store.state.regForm.kuja;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'kuja', value: value });
      }
    },
    budha: {
      get() {
        return this.$store.state.regForm.budha;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'budha', value: value });
      }
    },
    guru: {
      get() {
        return this.$store.state.regForm.guru;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'guru', value: value });
      }
    },
    rahu: {
      get() {
        return this.$store.state.regForm.rahu;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'rahu', value: value });
      }
    },
    sikuru: {
      get() {
        return this.$store.state.regForm.sikuru;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'sikuru', value: value });
      }
    },
    shani: {
      get() {
        return this.$store.state.regForm.shani;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'shani', value: value });
      }
    },
    kethu: {
      get() {
        return this.$store.state.regForm.kethu;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'kethu', value: value });
      }
    }
  }
}
</script>

<style>
</style>