<template>
  <div>
    <v-container>
      <v-form lazy-validation ref="step3">
        <v-row>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-text-field :rules="[requiredRule]" dense label="සම්පූර්ණ නම" v-model="fullName"></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-text-field :rules="[requiredRule]" dense label="භාවිතාවන ලිපිනය" v-model="address"></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[requiredRule, passwordRule]"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              counter
              dense
              hint="මුරපදය නැවත ඇතුල්වීමට අවශ්‍ය බැවින් සුරැකිව තබාගත යුතුය."
              label="ගිණුම සඳහා මුරපදය"
              v-model="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
        <v-col
          class="py-2"
        >ඔබට ගැලපෙන යෝජනා සහ ඔබට ලැබෙන ඇරයුම් පිළිබද විස්තර sms හරහා ක්ෂණිකව ඔබව දැනුවත් කිරීම සදහා මෙම දුරකථන අංකය භාවිත කෙරේ. මේ සදහා sms පහසුකම සහිත දුරකථන අංකයක් භාවිතා කරන්න. කිසිම අයුරකින් ඔබේ දුරකථන අංකය ඔබේ අනුදැනුමකින් තොරව අනෙක් පාර්ශවය වෙත හුවමාරු නොකෙරේ.</v-col>
        </v-row>-->
        <v-row>
          <v-col class="py-2" cols="12" md="4" sm="6">
            සාමාජිකයන් ව‌ෙත ලබාදිය යුතු දුරකථන අංකය
            <v-text-field
              :rules="[requiredRule]"
              dense
              placeholder="xxxxxxxxxx"
              type="number"
              v-model="mobileNumber"
            ></v-text-field>
          </v-col>
          <v-col class="py-2" cols="12" md="4" sm="6">
            ඔබව සම්බන්ධ කරගත හැකි ව‌ෙනත් දුරකථන අංකයක්
            <v-text-field
              :rules="[requiredRule]"
              dense
              placeholder="xxxxxxxxxx"
              type="number"
              v-model="secondaryMobileNumber"
            ></v-text-field>
          </v-col>
        </v-row>
        <hr/>
        <v-col class="py-2" cols="12" md="4" sm="6">
          විශේෂ
          </v-col>
        <v-row>
          <v-col class="py-2" cols="12" md="4" offset-md="2" sm="6">
            <v-text-field dense label="ව‌ෙනත් දුරකථන අංකයක්" prefix="+94" v-model="secondaryMobileNumber"></v-text-field>
          </v-col>
          <v-col class="py-2" cols="12" lg="3" md="4" sm="6">
            <v-select
              :items="metaData.phoneNumberShowing"
              dense
              item-text="label"
              item-value="value"
              label="විවාහ තත්වය"
              v-model="phoneNumShowStatus"
            ></v-select>
          </v-col>
        </v-row>

        <!-- <v-row>
      <v-col class="py-2">ඊමේල් ලිපිනය ඇත්නම් පමණක් ඇතුලත් කරන්න</v-col>
    </v-row>
    <v-row>
      <v-col class="py-2" cols="12" md="4" sm="6">
        <v-text-field dense label="ඊමේල් ලිපිනය" v-model="email"></v-text-field>
      </v-col>
        </v-row>-->

        <v-row>
          <v-btn
            @click="$emit('stepChanged', 2)"
            class="float-left mb-2"
            color="secondary"
            elevation="4"
          >
            <v-icon class="mr-2" dark>mdi-arrow-left-bold</v-icon>
            <span class="d-none d-sm-flex">පෙර පිටුව</span>
          </v-btn>
          <v-spacer></v-spacer>
          <a
            class="body-2 pt-3"
            href="https://sinhalaunicode.gishan.net/write"
            target="_blank"
            v-if="$vuetify.breakpoint.smAndUp"
          >සිංහලෙන් ටයිප් කිරීම සඳහා පිවිසෙන්න</a>
          <v-spacer></v-spacer>
          <v-btn @click="next" class="float-right mb-2" color="secondary" elevation="4">
            <span class="d-none d-sm-flex">ඊලග පිටුව</span>
            <v-icon class="ml-2" dark>mdi-arrow-right-bold</v-icon>
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
    <a
      class="d-flex justify-center body-2 pt-3"
      href="https://sinhalaunicode.gishan.net/write"
      target="_blank"
      v-if="$vuetify.breakpoint.xs"
    >සිංහලෙන් ටයිප් කිරීම සඳහා පිවිසෙන්න</a>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { UPDATE_REG_FORM } from "../../store/mutation-types";

export default {
  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    passwordRule: v => v.length >= 4 || 'මුරපදය අක්ෂර 4 ට වඩා වැඩි විය යුතුය',
    showPassword: false,
    countryCodes: ["+94", "+61", "+971", "+44", "+27", "+965", "+82", "+974", "+960", "+1", "+973", "+880",
      "+41", "+86", "+357", "+45", "+33", "+49", "+91", "+98", "+39", "+81", "+60", "+64", "+968", "+7",
      "+966", "වෙනත්"
    ],
  }),

  computed: {
    ...mapState(['metaData']),

    fullName: {
      get() {
        return this.$store.state.regForm.fullName;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'fullName', value: value });
      }
    },
    address: {
      get() {
        return this.$store.state.regForm.address;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'address', value: value });
      }
    },
    password: {
      get() {
        return this.$store.state.regForm.password;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'password', value: value });
      }
    },
    mobileNumber: {
      get() {
        return this.$store.state.regForm.mobileNumber;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'mobileNumber', value: value });
      }
    },
    secondaryMobileNumber: {
      get() {
        return this.$store.state.regForm.secondaryMobileNumber;
      },
      set(value) {
        this.$store.commit(UPDATE_REG_FORM, { property: 'secondaryMobileNumber', value: value });
      }
    },
    // phoneNumShowStatus: {
    //   get() {
    //     return this.$store.state.regForm.phoneNumShowStatus;
    //   },
    //   set(value) {
    //     this.$store.commit(UPDATE_REG_FORM, { property: 'phoneNumShowStatus', value: value });
    //   }
    // },
    // email: {
    //   get() {
    //     return this.$store.state.regForm.email;
    //   },
    //   set(value) {
    //     this.$store.commit(UPDATE_REG_FORM, { property: 'email', value: value });
    //   }
    // }
  },

  methods: {
    next() {
      if (this.$refs.step3.validate()) {
        this.$emit('stepChanged', 4);
      }
    }
  }
}
</script>