<template>
  <v-container>
    <h5 class="text-center">සොයන සහකරුග‌ේ/සහකාරියගේ විස්තර</h5>

    <!-- Success message -->
    <v-dialog max-width="800" persistent v-model="dialog">
      <v-card>
        <v-card-subtitle class="text-center">
          <v-icon class="mt-5 mb-0 pa-2" color="accent" id="alert-icon" left size="72">mdi-check</v-icon>
        </v-card-subtitle>

        <v-card-title class="justify-center">
          <h3
            style="font-family: Noto Sans Sinhala Light; color: #F19953"
          >ඔබගේ සාමාජික අංකය : {{username}}</h3>
        </v-card-title>
        <v-divider class="mt-0"></v-divider>

        <v-card-text class="pt-3" style="color: #FF5252">
          <h5>ගිණුම අවලංගු නොවී පවත්වා ගැනීමට</h5>
          <p>
            ඔබගේ ගිණුම තහවුරු කරන ලද ගිණුමක් ලෙස පවත්වා ගැනීම අනිවාර්ය වේ. එසේ නොවන ගිණුම් දැනුම් දීමකින් තොරව
            ආයතනය මගින් පැය 24ක් තුල අවලංගු කරනු ඇත. ඒ සඳහා ඔබ දී ඇති දුරකථන අංක,
          </p>
          <p>1. ජංගම අංකයක් නම් එම අංකයෙන් 0719-928628 අංකයට මෙම මොහොතේම ඔබගේ සාමාජික අංකය SMS කල යුතුය.</p>
          <p>
            2. ස්ථාවර අංකයක් නම් එම අංකයෙන් 0719-928628 අංකයට කාර්යාල වේලාවන් හිදී (පෙ.ව 9.30 - ප.ව 8.30, පෝය නිවාඩු හැර)
            ඇමතුමක් දී ඔබගේ සාමාජික අංකය පවසා තහවුරු කර ගත යුතුය.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadingHomeBtn"
            @click="backToHome"
            class="mb-3"
            color="accent"
            outlined
            x-large
          >
            <v-icon class="mr-3">mdi-home</v-icon>මුල් පිටුවට
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form lazy-validation ref="step7">
      <v-row class="mt-5" no-gutters>
        <v-col>
          <p class="mb-0 mt-4">ජාතිය වියයුත්තේ</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :key="index" cols="6" md="3" sm="4" v-for="(race, index) in metaData.races">
          <v-checkbox
            :label="race.name"
            :rules="[!!races.length || '']"
            :value="race.name"
            hide-details
            v-model="races"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mt-5" no-gutters>
        <v-col>
          <p class="mb-0 mt-4">ආගම වියයුත්තේ</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :key="index" cols="6" md="3" sm="4" v-for="(religion, index) in metaData.religions">
          <v-checkbox
            :label="religion.name"
            :rules="[!!religions.length || '']"
            :value="religion.name"
            v-model="religions"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mt-5" no-gutters>
        <v-col>
          <p class="mb-0 mt-4">කුලය වියයුත්තේ</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :key="index" cols="6" md="3" sm="4" v-for="(cast, index) in metaData.casts">
          <v-checkbox
            :label="cast.name"
            :rules="[!!casts.length || '']"
            :value="cast.name"
            v-model="casts"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mt-5" no-gutters>
        <v-col>
          <p class="mb-0 mt-4">දිස්ත්‍රික්කය වියයුත්තේ</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :key="index" cols="6" md="3" sm="4" v-for="(district, index) in metaData.districts">
          <v-checkbox
            :label="district.name"
            :rules="[!!districts.length || '']"
            :value="district.name"
            v-model="districts"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mt-5" no-gutters>
        <v-col>
          <p class="mb-0 mt-4">විවාහක තත්වය වියයුත්තේ</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          :key="index"
          cols="6"
          md="3"
          sm="4"
          v-for="(marriageStatus, index) in metaData.marriageStatuses"
        >
          <v-checkbox
            :label="marriageStatus.status"
            :rules="[!!marriageStatuses.length || '']"
            :value="marriageStatus.status"
            v-model="marriageStatuses"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mt-5" no-gutters>
        <v-col>
          <p class="mb-0 mt-4">අධ්‍යාපන මට්ටම වියයුත්තේ</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          :key="index"
          cols="12"
          md="3"
          sm="4"
          v-for="(education, index) in metaData.educations"
        >
          <v-checkbox
            :label="education.level"
            :rules="[!!educationLevels.length || '']"
            :value="education.level"
            v-model="educationLevels"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mt-5" no-gutters>
        <v-col>
          <p class="mb-0 mt-4">රැකියා අංශය වියයුත්තේ</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col :key="index" cols="12" md="3" sm="4" v-for="(job, index) in metaData.jobs">
          <v-checkbox
            :label="job.name"
            :rules="[!!jobs.length || '']"
            :value="job.name"
            v-model="jobs"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mt-5" no-gutters>
        <v-col>
          <p class="mb-0 mt-4">මාසික අදායම වියයුත්තේ</p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          :key="index"
          cols="12"
          md="3"
          sm="4"
          v-for="(monthlyIncome, index) in metaData.monthlyIncomes"
        >
          <v-checkbox
            :label="monthlyIncome.value"
            :rules="[!!monthlyIncomes.length || '']"
            :value="monthlyIncome.value"
            v-model="monthlyIncomes"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mt-5" no-gutters>
        <v-col>
          <p class="mb-0 mt-4">අවම හා උපරිම වයස වියයුත්තේ</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-4">
          <v-range-slider
            :value="ageRange"
            @change="setAgeRange"
            class="mt-8 align-center"
            hide-details
            max="70"
            min="18"
            thumb-label="always"
          ></v-range-slider>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-checkbox class="mb-12 mb-sm-8" v-model="acceptConditions">
        <template slot="label">
          <span class="mb-n1">ආයතනයේ නීති හා රෙගුලාසි වලට එකග වෙමි.</span>
        </template>
        <template slot="append">
          <router-link class="body-2 mb-n5" target="_blank" to="/terms-and-conditions">(බලන්න)</router-link>
        </template>
      </v-checkbox>

      <v-btn
        @click="$emit('stepChanged', 6)"
        class="float-left mb-2"
        color="secondary"
        elevation="4"
      >
        <v-icon class="mr-2" dark>mdi-arrow-left-bold</v-icon>
        <span class="d-none d-sm-flex">පෙර පිටුව</span>
      </v-btn>
      <v-btn
        :disabled="!acceptConditions"
        :loading="loadingRegisterBtn"
        @click="register"
        class="float-right mb-2"
        color="secondary"
        elevation="4"
      >
        <span class="d-none d-sm-flex">ඉදිරිපත් කරන්න</span>
        <v-icon class="ml-2" dark>mdi-check</v-icon>
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { UPDATE_FINDER_PROPERTIES, RESET_REGISTRATION_FORM } from "../../store/mutation-types";

export default {
  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    loadingRegisterBtn: false,
    dialog: false,
    username: '',
    loadingHomeBtn: false,
    acceptConditions: false
  }),

  computed: {
    ...mapState(['metaData']),

    races: {
      get() {
        return this.$store.state.regForm.finder.races;
      },
      set(value) {
        this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'races', value: value });
      }
    },
    religions: {
      get() {
        return this.$store.state.regForm.finder.religions;
      },
      set(value) {
        this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'religions', value: value });
      }
    },
    casts: {
      get() {
        return this.$store.state.regForm.finder.casts;
      },
      set(value) {
        this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'casts', value: value });
      }
    },
    districts: {
      get() {
        return this.$store.state.regForm.finder.districts;
      },
      set(value) {
        this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'districts', value: value });
      }
    },
    marriageStatuses: {
      get() {
        return this.$store.state.regForm.finder.marriageStatuses;
      },
      set(value) {
        this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'marriageStatuses', value: value });
      }
    },
    educationLevels: {
      get() {
        return this.$store.state.regForm.finder.educationLevels;
      },
      set(value) {
        this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'educationLevels', value: value });
      }
    },
    jobs: {
      get() {
        return this.$store.state.regForm.finder.jobs;
      },
      set(value) {
        this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'jobs', value: value });
      }
    },
    // liquireUsages: {
    //   get() {
    //     return this.$store.state.regForm.finder.liquireUsages;
    //   },
    //   set(value) {
    //     this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'liquireUsages', value: value });
    //   }
    // },
    // smokeUsages: {
    //   get() {
    //     return this.$store.state.regForm.finder.smokeUsages;
    //   },
    //   set(value) {
    //     this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'smokeUsages', value: value });
    //   }
    // },
    monthlyIncomes: {
      get() {
        return this.$store.state.regForm.finder.monthlyIncomes;
      },
      set(value) {
        this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'monthlyIncomes', value: value });
      }
    },
    // foodHabbits: {
    //   get() {
    //     return this.$store.state.regForm.finder.foodHabbits;
    //   },
    //   set(value) {
    //     this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'foodHabbits', value: value });
    //   }
    // },
    ageRange() {
      return this.$store.state.regForm.finder.ageRange;
    }
  },

  methods: {
    setAgeRange: function (value) {
      this.$store.commit(UPDATE_FINDER_PROPERTIES, { property: 'ageRange', value: value });
    },

    register: async function () {
      if (this.$refs.step7.validate()) {
        this.loadingRegisterBtn = true;
        try {
          const response = await this.$store.dispatch('register');
          this.username = response.data.id;
          this.dialog = true;
        } catch (error) {
          console.log(error);
        }
        this.loadingRegisterBtn = false;
      }
    },

    backToHome: async function () {
      this.loadingHomeBtn = true;
      await this.$store.commit(RESET_REGISTRATION_FORM);
      await this.$router.push('/');
      this.loadingHomeBtn = false;
    }
  }
}
</script>

<style>
#alert-icon {
  border: 3px solid #eeeeee;
  border-radius: 100%;
}
</style>