<template>
  <div>
    <v-stepper alt-labels class="custom-shadow" v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" color="secondary" step="1">පළමු පියවර</v-stepper-step>
        <v-stepper-step :complete="e1 > 2" color="secondary" step="2">දෙවන පියවර</v-stepper-step>
        <v-stepper-step :complete="e1 > 3" color="secondary" step="3">තෙවන පියවර</v-stepper-step>
        <v-stepper-step :complete="e1 > 4" color="secondary" step="4">සිව්වන පියවර</v-stepper-step>
        <v-stepper-step :complete="e1 > 5" color="secondary" step="5">පස්වන පියවර</v-stepper-step>
        <v-stepper-step :complete="e1 > 6" color="secondary" step="6">සයවන පියවර</v-stepper-step>
        <v-stepper-step :complete="e1 > 7" color="secondary" step="7">හත්වන පියවර</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- First step -->
        <v-stepper-content step="1">
          <Step1 @stepChanged="changeStep" />
        </v-stepper-content>

        <!-- Second step -->
        <v-stepper-content step="2">
          <Step2 @stepChanged="changeStep" />
        </v-stepper-content>

        <!-- Third step -->
        <v-stepper-content step="3">
          <Step3 @stepChanged="changeStep" />
        </v-stepper-content>

        <!-- Fourth step -->
        <v-stepper-content step="4">
          <Step4 @stepChanged="changeStep" />
        </v-stepper-content>

        <!-- Fifth step -->
        <v-stepper-content step="5">
          <Step5 @stepChanged="changeStep" />
        </v-stepper-content>

        <!-- Sixth step -->
        <v-stepper-content step="6">
          <Step6 @stepChanged="changeStep" />
        </v-stepper-content>

        <!-- Seventh step -->
        <v-stepper-content step="7">
          <Step7 @stepChanged="changeStep" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import Vue from "vue";
import { ApiService } from '../../services/api.service';
import { SET_DEFAULT_PACKAGE_ID } from '../../store/mutation-types';

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";

export default {
  name: "RegisterForm",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7
  },
  data: () => ({
    e1: 0,
  }),

  methods: {
    changeStep: function (step) {
      this.e1 = step;
    }
  }
};
</script>

<style>
.v-stepper__step {
  flex-basis: auto !important;
}
</style>